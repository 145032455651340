import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api-corretor.loopimoveis.com/', 
  // baseURL: 'http://localhost:3005/', 
  timeout: 35000,
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('broker_token');

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default api;