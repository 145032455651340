import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { UserContextProvider } from "./Contexts/User/provider";
import { PendingLeadsProvider } from "./Contexts/PendingLeads/provider";
import { OldLeadsProvider } from "./Contexts/OldLeads/provider";
import "antd/dist/antd.css";

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <PendingLeadsProvider>
        <OldLeadsProvider>
          <App />
        </OldLeadsProvider>
      </PendingLeadsProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
