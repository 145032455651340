import { useEffect, useState, useCallback, useContext } from "react";
import {
  useLeadsController,
  TYPE_LEAD,
} from "../../Controllers/LeadsController";
import { List, Empty, Typography } from "antd";
import { PendingAndOldLeadCard } from "../../Components/PendingAndOldLeadCard";
import { PendingLeadsContext } from "../../Contexts/PendingLeads/provider";

const { Title, Paragraph } = Typography;

export const Notifications = () => {
  const [loadingPendingLeads, setLoadingPendingLeads] = useState(true);
  const [loadingOldLeads, setLoadingOldLeads] = useState(true);
  const [leadsPendentes, setLeadsPendentes] = useState([]);
  const [leadsActiveOffer, setLeadsActiveOffer] = useState([]);
  const [oldLeads, setOldLeads] = useState([]);
  const { setPendingLeadAccepted } = useContext(PendingLeadsContext);

  const { getAvailableLeads, getOldLeads, acceptLead } = useLeadsController();

  const filterNovoLead = (lead) =>
    !lead.typeLead || lead.typeLead === TYPE_LEAD.novoLead;

  const filterLeadActiveOffer = (lead) =>
    lead.typeLead && lead.typeLead !== TYPE_LEAD.novoLead;

  const fetchLeadsPendentes = useCallback(async () => {
    setLoadingPendingLeads(true);
    try {
      const { data } = await getAvailableLeads();

      if (data) {
        setLeadsPendentes(data.filter(filterNovoLead));
        setLeadsActiveOffer(data.filter(filterLeadActiveOffer));
      } else {
        setLeadsPendentes([]);
        setLeadsActiveOffer([]);
      }
    } catch (err) {
      setLeadsPendentes([]);
      setLeadsActiveOffer([]);
    }

    setLoadingPendingLeads(false);
  }, [getAvailableLeads]);

  const fetchOldLeads = useCallback(async () => {
    setLoadingOldLeads(true);
    try {
      const { data } = await getOldLeads();

      if (data) {
        setOldLeads(data);
      } else {
        setOldLeads([]);
      }
    } catch (err) {
      setOldLeads([]);
    }

    setLoadingOldLeads(false);
  }, [getOldLeads]);

  useEffect(() => {
    fetchLeadsPendentes();
    fetchOldLeads();
  }, [fetchLeadsPendentes, fetchOldLeads]);

  const handleAceitar = async (id) => {
    const result = await acceptLead(id);
    window.analytics.track("Aceitar Lead Pendente");
    fetchLeadsPendentes();
    setPendingLeadAccepted(id);
  };

  return (
    <>
      {!loadingPendingLeads &&
        !loadingOldLeads &&
        leadsPendentes.length === 0 &&
        leadsActiveOffer.length === 0 &&
        oldLeads.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Você não tem notificações pendentes. Parabéns!"
          />
        )}
      {/* Leads Pendentes */}
      {leadsPendentes.length !== 0 && (
        <>
          <Title level={2}>Leads Pendentes</Title>
          <Paragraph style={{ maxWidth: "520px" }}>
            Leads pendentes para aceitar
          </Paragraph>
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            // loadMore={loadMore}
            loading={loadingPendingLeads}
            dataSource={leadsPendentes}
            style={{ maxWidth: "520px" }}
            renderItem={(item) => (
              <PendingAndOldLeadCard
                lead={item}
                loading={loadingPendingLeads}
                type="pending"
                handleAceitar={handleAceitar}
              />
            )}
          />
        </>
      )}
      {/* Leads Oferta Ativa */}
      {leadsActiveOffer.length !== 0 && (
        <>
          <Title level={2} style={{ marginTop: "32px" }}>
            Leads Oferta Ativa
          </Title>
          <Paragraph style={{ maxWidth: "520px" }}>
            Leads Oferta Ativa pendentes para aceitar
          </Paragraph>
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            // loadMore={loadMore}
            loading={loadingPendingLeads}
            dataSource={leadsActiveOffer}
            style={{ maxWidth: "520px" }}
            renderItem={(item) => (
              <PendingAndOldLeadCard
                lead={item}
                loading={loadingPendingLeads}
                type="pending"
                handleAceitar={handleAceitar}
              />
            )}
          />
        </>
      )}

      {/* Leads Antigos */}
      {oldLeads.length !== 0 && (
        <>
          <Title level={2} style={{ marginTop: "32px" }}>
            Leads Antigos
          </Title>
          <Paragraph style={{ maxWidth: "520px" }}>
            Leads que não mudaram de status por um longo tempo e/ou comentários
            não foram adicionados recentemente.
          </Paragraph>

          <List
            className="demo-loadmore-list"
            style={{ maxWidth: "520px" }}
            itemLayout="horizontal"
            // loadMore={loadMore}
            loading={loadingOldLeads}
            dataSource={oldLeads}
            renderItem={(item) => (
              <PendingAndOldLeadCard
                lead={item}
                loading={loadingOldLeads}
                type="old"
              />
            )}
          />
        </>
      )}
    </>
  );
};
