import api from "../../service/api";
import {useState} from "react";

export const usePropertyController = () => {
    const [loadingProperty, setLoadingProperty] = useState(false);
    const [loadingSimilar, setLoadingSimilar] = useState(false);
    const [property, setProperty] = useState(null);
    const [similar, setSimilar] = useState(null);

    const getProperty = async (propertyId) => {
        setLoadingProperty(true);

        const {data} = await api.get("/properties/find/" + propertyId);
        if(data) {
            console.log("data de prop es ", data)
            setProperty(data);
        }

        setLoadingProperty(false);
    }

    const getSimilar = async (propertyId) => {
        setLoadingSimilar(true);

        const {data} = await api.get("/similar/" + propertyId);
        if(data) {
            setSimilar(data);
        }

        setLoadingSimilar(false);
    }

    return {
        getProperty,
        loadingProperty,
        property,

        getSimilar,
        loadingSimilar,
        similar
    }
}
