import { useState, useCallback, useEffect } from "react";
import { Row, Col, Typography, Button } from "antd";
import { useLocation } from "wouter";
import {
  SalesFunnelBar,
  SalesFunnelBarSkeleton,
} from "../../Components/SalesFunnelBar";
import {
  useLeadsController,
  STATUS_OPTIONS_PLURAL,
} from "../../Controllers/LeadsController";
import styles from "./SalesFunnel.module.css";

const { Text } = Typography;

export const SalesFunnel = () => {
  const [, setLocation] = useLocation();
  const [loading, setLoading] = useState(true);
  const [salesFunnel, setSalesFunnel] = useState([]);
  const { getFunnil } = useLeadsController();

  const fetchSalesFunnel = useCallback(async () => {
    const data = await getFunnil();

    if (data) {
      setSalesFunnel(data);
    } else {
      setSalesFunnel([]);
    }
    setLoading(false);
  }, [getFunnil]);

  useEffect(() => {
    fetchSalesFunnel();
  }, [fetchSalesFunnel]);

  return (
    <div>
      <Row justify="space-between">
        <Col className={styles.leftSide}>
          <Text className={styles.text}>% de Conversão</Text>
        </Col>
        <Col className={styles.centerSide}>
          <Text className={styles.text}>Etapa de Funil</Text>
        </Col>
        <Col className={styles.rightSide}>
          <Text className={styles.text}>Leads no Funil</Text>
        </Col>
      </Row>
      <Col>
        {loading
          ? Object.keys(STATUS_OPTIONS_PLURAL).map((value, i) => (
              <SalesFunnelBarSkeleton
                key={`sales_funnel_bar_skeleton_${i}`}
                position={i + 1}
              />
            ))
          : salesFunnel.map((sale, i) => (
              <Button
                key={`sale_funnel_bar_${i}`}
                type="link"
                onClick={() => {
                  setLocation(`/leads/filters/status=${sale.selectValue}`);
                }}
                style={{ width: "100%", marginTop: "16px" }}
              >
                <SalesFunnelBar
                  key={`sale_funnel_bar_${i}`}
                  title={sale.name}
                  percentage={sale.percentage}
                  count={sale.count}
                  position={i + 1}
                  loading={loading}
                />
              </Button>
            ))}
      </Col>
    </div>
  );
};
