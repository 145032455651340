import { Row, Col, Typography, Skeleton, Tooltip } from "antd";
import styles from "./SalesFunnelBar.module.css";

const { Text } = Typography;

const minWidth = 50;

const calculateBarWidth = (position) => {
  const subtractWidth = position === 1 ? 0 : position * 5 - 5;
  return 100 - subtractWidth > minWidth ? 100 - subtractWidth : minWidth;
};

export const SalesFunnelBar = ({
  title,
  percentage,
  count,
  position,
  loading,
}) => {
  const width = calculateBarWidth(position);

  if (loading) {
    return <SalesFunnelBarSkeleton position={position} />;
  }

  return (
    <Row justify="center">
      <Row
        align="middle"
        className={styles.bar}
        style={{
          width: `${width}%`,
        }}
      >
        <Col span={4} className={styles.leftSide}>
          <div className={styles.percentageBar}>
            <Text className={styles.percentageText} strong>
              {percentage}%
            </Text>
          </div>
        </Col>
        <Col span={16} className={styles.centerSide}>
          <Text className={styles.centeredText} strong>
            {title}
          </Text>
        </Col>
        <Col span={4} className={styles.rightSide}>
          <div className={styles.count}>
            <Text className={styles.countText} strong>
              {parseInt(count)}
            </Text>
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export const SalesFunnelBarSkeleton = ({ position }) => {
  const width = calculateBarWidth(position);

  return (
    <Row justify="center">
      <Row
        align="middle"
        className={styles.bar}
        style={{
          width: `${width}%`,
        }}
      >
        <Col span={4} className={styles.leftSide}>
          <Skeleton.Button active shape="round" />
        </Col>
        <Col span={16} className={styles.centerSideofSkeleton}>
          <Skeleton active paragraph={{ rows: 0 }} />
        </Col>
        <Col span={4} className={styles.rightSide}>
          <Skeleton.Button
            className={styles.marginRight}
            shape="circle"
            active
          />
        </Col>
      </Row>
    </Row>
  );
};
