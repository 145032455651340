import { useContext, useEffect, useState, useCallback } from "react";
import { useRoute, useLocation } from "wouter";
import { UserContext } from "../../Contexts/User/provider";
import { useLeadsController } from "../../Controllers/LeadsController";
import {
  Input,
  message,
  Collapse,
  PageHeader,
  Row,
  Col,
  List,
  Modal,
  Button,
  Typography,
  DatePicker,
  Radio,
  TimePicker,
  Tooltip,
} from "antd";
import { Conversation } from "../../Components/Conversation";
import { Observations } from "../../Components/Observations";
import { LeadStatus } from "../../Components/LeadStatus";
import { NotFoundPage } from "../../App";
import { InfoLead } from "../../Components/InfoLead";
import { usePropertyController } from "../../Controllers/PropertyController";
import {
  useTaskController,
  TASK_TYPES,
} from "../../Controllers/TaskController";
import { STATUS_OPTIONS } from "../../Controllers/LeadsController";
import moment from "moment";
import locale from "antd/es/date-picker/locale/pt_BR";
import { OldLeadsContext } from "../../Contexts/OldLeads/provider";
import { TaskTable } from "../../Components/TaskTable";

const { TextArea } = Input;

export const LeadPage = () => {
  const [location, setLocation] = useLocation();
  const [, params] = useRoute("/leads/:id");
  const { user } = useContext(UserContext);
  const {
    getLead,
    getChat,
    getComments,
    editStatus,
    addComment,
  } = useLeadsController();
  const [step, setStep] = useState(0);
  const [newTaskType, setNewTaskType] = useState();
  const defaultCalendarValue = moment();
  const defaultTimePickerValue = moment();
  const [newTaskDate, setNewTaskDate] = useState(defaultCalendarValue);
  const [newTaskTime, setNewTaskTime] = useState(defaultTimePickerValue);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showModalCreateTask, setShowModalCreateTask] = useState(false);
  const [lead, setLead] = useState({});
  const [chat, setChat] = useState([]);
  const [observations, setObservations] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [loadingStatusChange, setLoadingStatusChange] = useState(false);
  const [loadingObservations, setLoadingObservations] = useState(false);
  const [nextStatus, setNextStatus] = useState();
  const [newTaskObservation, setNewTaskObservation] = useState();
  const { loadingProperty, getProperty, property } = usePropertyController();
  const {
    findTasks,
    loadingFindTasks,
    taskList,
    createTask,
    loadingCreateTask,
    taskCreated,
    completeTask,
    loadingCompleteTask,
  } = useTaskController();

  const { setModifiedStatusOrAddedObservationOfOldLead } = useContext(
    OldLeadsContext
  );

  // const Step = Steps.Step;

  const onClickFinishButton = () => {
    createTask(
      newTaskDate.format("DD/MM/YYYY"),
      newTaskTime.format("HH:mm"),
      lead.id,
      newTaskType
    ).then(() => {
      window.analytics.track("Criar tarefa", {
        type: newTaskType,
      });
      findTasks(params.id);
    });

    // if there is a new observation
    if (newTaskObservation) {
      if (nextStatus) {
        const statusText = STATUS_OPTIONS[nextStatus];
        handleNewObservation("[" + statusText + "] " + newTaskObservation);
      } else {
        handleNewObservation(newTaskObservation);
      }
    }
  };

  const FinishButton = () => {
    return (
      <Button
        key="submit"
        type="primary"
        loading={loadingCreateTask}
        onClick={onClickFinishButton}
      >
        Finalizar
      </Button>
    );
  };
  const contentForStep = [
    {
      // 0
      content: (
        <>
          <p>
            Vai mudar o status para{" "}
            <Typography.Text code>{STATUS_OPTIONS[nextStatus]}</Typography.Text>
            .
          </p>
          {nextStatus === 6 ? (
            <p>Tem certeza de que deseja reclassificar o Lead?</p>
          ) : (
            <p>Quer criar uma tarefa?</p>
          )}
        </>
      ),
      footer: [
        // STATUS_OPTIONS Perdido
        nextStatus === 6 ? (
          <Button
            key="back"
            onClick={() => {
              handleCloseTaskModal();
            }}
          >
            Não, eu ainda quero ter esse lead
          </Button>
        ) : (
          <Button
            key="back"
            onClick={() => {
              changeStatus();
              handleCloseTaskModal();
            }}
          >
            Não, continue sem criar uma tarefa.
          </Button>
        ),
        // STATUS_OPTIONS Perdido
        nextStatus === 6 ? (
          <Button
            key="submit"
            type="danger"
            onClick={() => {
              changeStatus();
              handleCloseTaskModal();
            }}
          >
            Sim, mude para perdido
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              nextStep();
            }}
          >
            Criar tarefa
          </Button>
        ),
      ],
    },
    {
      // 1
      content: (
        <>
          <Radio.Group
            onChange={(newType) => {
              setNewTaskType(newType.target.value);
            }}
          >
            {TASK_TYPES.map((task) => {
              return (
                <Radio
                  key={task}
                  value={task}
                  style={{ display: "block", marginBottom: "8px" }}
                >
                  {task}
                </Radio>
              );
            })}
          </Radio.Group>
        </>
      ),
      footer: [
        !showModalCreateTask && (
          <Button
            key="back"
            onClick={() => {
              prevStep();
            }}
          >
            Retornar
          </Button>
        ),
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            if (!TASK_TYPES.includes(newTaskType)) {
              return;
            }
            nextStep();
          }}
        >
          Segue
        </Button>,
      ],
    },
    {
      // 2
      content: (
        <>
          <p>Que dia vai ser?</p>
          <div style={{ width: "300px" }}>
            <DatePicker
              defaultValue={defaultCalendarValue}
              format={"DD/MM/YYYY"}
              onSelect={(e) => {
                setNewTaskDate(e);
              }}
              locale={locale}
            />
          </div>
        </>
      ),
      footer: [
        <Button
          key="back"
          onClick={() => {
            prevStep();
          }}
        >
          Retornar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            if (newTaskDate === undefined) {
              return;
            }
            nextStep();
          }}
        >
          Segue
        </Button>,
      ],
    },
    {
      // 3
      content: (
        <>
          <p>Que hora vai ser?</p>
          <TimePicker
            defaultValue={defaultTimePickerValue}
            format={"HH:mm"}
            minuteStep={5}
            onSelect={(e) => {
              setNewTaskTime(e);
            }}
            locale={locale}
          />
        </>
      ),
      footer: [
        <Button
          key="back"
          onClick={() => {
            prevStep();
          }}
        >
          Retornar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingCreateTask}
          onClick={() => {
            nextStep();
          }}
        >
          Segue
        </Button>,
      ],
    },
    {
      // 4
      content: (
        <>
          <p>Você quer adicionar uma observação?</p>
          <Input.TextArea
            rows={4}
            onChange={(e) => {
              setNewTaskObservation(e.target.value);
            }}
            placeholder="Escreva uma observação"
          />
        </>
      ),
      footer: [
        <Button
          key="back"
          onClick={() => {
            prevStep();
          }}
        >
          Retornar
        </Button>,
        <FinishButton />,
      ],
    },
  ];

  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const addTask = (e) => {
    e.stopPropagation();
    setStep(step + 1);
    setShowModalCreateTask(true);
    setShowTaskModal(true);
  };

  const fetchLead = async () => {
    const { data } = await getLead(params.id);

    if (data && data.agentId === user.id) {
      setLead(data);
    } else {
      setLead({});
      setNotFound(true);
    }

    return data;
  };

  useEffect(() => {
    fetchLead().then((leadResult) => {
      getProperty(leadResult.propertyId);
    });
    findTasks(params.id);
  }, [getLead]);

  useEffect(() => {
    const fetchChat = async () => {
      const { data } = await getChat(params.id);

      if (data[0].id !== 0) {
        setChat(data[0].message.message);
      } else {
        setChat([]);
      }
    };

    fetchChat();
  }, [getChat]);

  useEffect(() => {
    if (taskCreated) {
      message.success("Tarefa criada com sucesso");
      if (!showModalCreateTask) {
        changeStatus();
      }
      handleCloseTaskModal();
    }
  }, [taskCreated]);

  useEffect(() => {
    const fetchComment = async () => {
      setLoadingObservations(true);
      const { data } = await getComments(params.id);
      setLoadingObservations(false);
      if (data) {
        setObservations(data);
      }
    };
    fetchComment();
  }, [getComments]);

  const handleCloseTaskModal = () => {
    setShowTaskModal(false);
    setShowModalCreateTask(false);
    setStep(0);
    setNextStatus();
    setNewTaskType();
    setNewTaskObservation();
  };

  const handleClickAnotherStatus = async (_status) => {
    setNextStatus(_status);
    setShowTaskModal(true);
  };

  // const changeStatus = async (_status) => {
  const changeStatus = async () => {
    setLoadingStatusChange(true);
    window.analytics.track("Mudar de estado", {
      new: nextStatus,
      old: lead.status,
    });
    const { data } = await editStatus(lead.id, nextStatus);
    setLoadingStatusChange(false);

    if (data) {
      setModifiedStatusOrAddedObservationOfOldLead(lead.id);
      setLead({ ...lead, status: nextStatus });
      message.success("Status atualizado corretamente");
    }
  };

  const handleNewObservation = async (text) => {
    setLoadingObservations(true);
    const { data } = await addComment(text, lead.id);
    if (data?.id) {
      window.analytics.track("Adicionar observação");
      setObservations([data, ...observations]);
      message.success("Observação criada corretamente");
      setModifiedStatusOrAddedObservationOfOldLead(lead.id);
    }

    setLoadingObservations(false);
  };

  const onCompleTask = async (id) => {
    await completeTask(id);
    findTasks(params.id);
    window.analytics.track("Tarefa completa");
  };

  if (notFound) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Modal
        title={showModalCreateTask ? "Criar tarefa" : "Mudança de status"}
        visible={showTaskModal}
        onCancel={handleCloseTaskModal}
        footer={contentForStep[step].footer}
      >
        {contentForStep[step].content}
      </Modal>
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
        }}
      >
        <Row gutter={8} style={{ marginTop: "16px", marginBottom: "16px" }}>
          <Col span={24}>
            <PageHeader
              style={{ padding: 0 }}
              onBack={() => window.history.back()}
              title="Lead"
            />
            <InfoLead
              lead={lead}
              property={property}
              loadingProperty={loadingProperty}
            />
          </Col>
        </Row>
        <Row gutter={8} style={{ marginTop: "32px", marginBottom: "32px" }}>
          <Col span={24}>
            <Typography.Title level={5}>Status</Typography.Title>
            {/* <Typography.Text>Status</Typography.Text> */}
            <LeadStatus
              status={lead.status}
              onChange={(e) => {
                handleClickAnotherStatus(e);
              }}
              loading={loadingStatusChange}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Collapse defaultActiveKey={["1", "2", "3"]}>
              <Collapse.Panel
                header="Tarefas"
                key="1"
                extra={
                  <Tooltip title="Criar nova tarefa">
                    <Button onClick={addTask}>Criar Tarefa</Button>
                  </Tooltip>
                }
              >
                <TaskTable
                  onCompleTask={onCompleTask}
                  taskList={taskList}
                  loading={loadingFindTasks}
                  loadingCompleteTask={loadingCompleteTask}
                />
              </Collapse.Panel>
              <Collapse.Panel header="Observações" key="2">
                <Observations
                  observations={observations}
                  loading={loadingObservations}
                  newObservation={handleNewObservation}
                />
              </Collapse.Panel>
              <Collapse.Panel header="Conversas" key="3">
                <Conversation conversation={chat} loading={false} />
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </div>
    </>
  );
};
