import { Button, Spin, Tooltip } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useLeadsController } from "../../Controllers/LeadsController";
import { Link } from "wouter";
import { usePropertyController } from "../../Controllers/PropertyController";

export const PropertyButton = ({ task }) => {
  // console.log("task es", task);

  const [imovelButtonLoading, setImovelButtonLoading] = useState(false);
  const [leadLoading, setLeadLoading] = useState(false);
  const [lead, setLead] = useState({});
  const { getLead } = useLeadsController();
  const { loadingProperty, getProperty, property } = usePropertyController();

  const fetchLead = async (task) => {
    const { data } = await getLead(task.leadId);

    if (data) {
      setLead(data);
    } else {
      setLead({});
    }
    setLeadLoading(false);
    return data;
  };

  // const handleClickOnImovelButton = (task) => {
  //   console.log("click", task);
  //   setImovelButtonLoading(true);
  //   fetchLead(task).then((leadResult) => {
  //     setImovelButtonLoading(false);
  //     window.open(leadResult.link, "_blank");
  //   });
  // };

  useEffect(() => {
    setImovelButtonLoading(true);
    fetchLead(task).then(async (leadResult) => {
      await getProperty(leadResult.propertyId);
      setImovelButtonLoading(false);
    });
  }, [task]);

  return (
    <>
      <Tooltip title="Ver propriedade em AoCubo">
        <a href={lead.link} target="_blank" rel="noreferrer">
          {imovelButtonLoading || loadingProperty ? (
            <Spin style={{ marginLeft: "32px" }} />
          ) : (
            <>
              <LinkOutlined />{" "}
              {property && property.local ? property.local : "Veja em AoCubo"}
            </>
          )}
        </a>
      </Tooltip>

      {/* <Tooltip title="Ver propriedade em AoCubo">
        <Button
          onClick={() => {
            handleClickOnImovelButton(task);
          }}
          loading={imovelButtonLoading}
          size={"small"}
          style={{ marginTop: "5px" }}
        >
          {imovelButtonLoading ? "" : <LinkOutlined />}
          Veja em AoCubo
        </Button>
      </Tooltip> */}
    </>
  );
};
