import api from "../../service/api";
import { useContext, useState } from "react";
import { UserContext } from "../../Contexts/User/provider";
import { useLeadsController } from "../LeadsController";
import moment from "moment";
import "moment/locale/pt-br";
import dayjs from "dayjs";

moment.locale("pt-br");

export const TASK_TYPES = [
  "Reunião",
  "Outro",
  "Visita no Imóvel",
  "Ligação",
  "Proposta",
];

export const TASK_STATUS = {
  completed: 1,
};

export const useTaskController = () => {
  const [loadingTaskList, setLoadingTaskList] = useState(false);
  const [loadingTask, setLoadingTask] = useState(false);
  const [loadingCreateTask, setLoadingCreateTask] = useState(false);
  const [loadingDeleteTask, setLoadingDeleteTask] = useState(false);
  const [loadingCompleteTask, setLoadingCompleteTask] = useState(false);
  const [loadingFindTasks, setLoadingFindTasks] = useState(false);

  const [errorViewTask, setErrorViewTask] = useState(false);

  const [taskList, setTaskList] = useState([]);
  const [task, setTask] = useState(null);
  const [taskCreated, setTaskCreated] = useState(null);

  const { getLead } = useLeadsController();

  const { user } = useContext(UserContext);

  const filterCompletedTasks = (task) => task.status !== TASK_STATUS.completed;

  const formatTaskDates = (task) => {
    let date;
    if (dayjs(task.date, "D-M-YYYY").isValid()) {
      date = dayjs(task.date, "D-M-YYYY").format("DD/MM/YYYY")
    } else {
      date = moment(task.date, "DD/MM/YYYY").format("DD/MM/YYYY");
    }

    return { ...task, date };
  };

  const formatDatesWithTime = (dateWithTime) => {
    return moment(dateWithTime, "DD/MM/YYYY HH:mm").isValid()
      ? moment(dateWithTime, "DD/MM/YYYY HH:mm")
      : "";
  };

  const sortTasksbyDate = (taskA, taskB) => {
    const dayA = formatDatesWithTime(`${taskA.date} ${taskA.time}`);
    const dayB = formatDatesWithTime(`${taskB.date} ${taskB.time}`);

    if (dayA && dayA.isSameOrBefore(dayB)) {
      return -1; // return dayA
    } else {
      return 1; // return dayB
    }
  };

  const getTaskList = async () => {
    setLoadingTaskList(true);

    const { data } = await api.get("tasks/list");
    if (data) {
      setTaskList(
        data
          .filter(filterCompletedTasks)
          .map(formatTaskDates)
          .sort(sortTasksbyDate)
      );
    }

    setLoadingTaskList(false);
  };

  const getTask = async (taskId) => {
    setLoadingTask(true);
    const { data } = await api.get("/tasks/find/" + taskId);
    if (data?.userId === user.id) {
      setTask(data);
    } else {
      setErrorViewTask(true);
    }
    setLoadingTask(false);

    return data;
  };

  /**
   * @param type Visita ao imóvel | Reunião | Ligação | Proposta | Outro
   */
  const createTask = async (date, time, leadId, type) => {
    setLoadingCreateTask(true);

    const response = await getLead(leadId);
    const leadContact = response.data.contact;

    const { data } = await api.post("tasks", {
      date,
      time,
      leadContact,
      leadId,
      type,
      userId: user.id,
      status: 0,
      locale: "",
    });

    if (data?.id) {
      setTaskCreated(data);
    }

    setLoadingCreateTask(false);
  };

  const deleteTask = async (taskId) => {
    setLoadingDeleteTask(true);

    await getTask(taskId).then(async (response) => {
      if (response.userId === user.id) {
        await api.delete("/tasks/taskDel/" + taskId);
        setLoadingDeleteTask(false);
      } else {
        setLoadingDeleteTask(false);
      }
    });
  };

  const completeTask = async (taskId) => {
    setLoadingCompleteTask(true);

    await getTask(taskId).then(async (response) => {
      if (response.userId === user.id) {
        await api.put("/tasks/changeStatus/" + taskId, { status: 1 });
        setLoadingCompleteTask(false);
      } else {
        setLoadingCompleteTask(false);
      }
    });
  };

  const findTasks = async (leadId) => {
    setLoadingFindTasks(true);
    const { data } = await api.get("/tasks/taskLead/" + leadId);
    if (data) {
      setTaskList(
        data
          .filter(filterCompletedTasks)
          .map(formatTaskDates)
          .sort(sortTasksbyDate)
      );
    }
    setLoadingFindTasks(false);
  };

  return {
    getTaskList,
    loadingTaskList,
    taskList,

    getTask,
    loadingTask,
    errorViewTask,
    task,

    createTask,
    loadingCreateTask,
    taskCreated,
    setTaskCreated,

    deleteTask,
    loadingDeleteTask,

    completeTask,
    loadingCompleteTask,

    findTasks,
    loadingFindTasks,
  };
};
