import {
  Button,
  Layout as AntdLayout,
  Menu,
  Badge,
  Tooltip,
  Drawer,
} from "antd";
import { useContext, useState, useEffect, useCallback } from "react";
import { UserContext } from "../Contexts/User/provider";
import { useAuthController } from "../Controllers/AuthController";
import { Link, useLocation } from "wouter";
import {
  CopyOutlined,
  ContactsOutlined,
  FunnelPlotOutlined,
  AlertOutlined,
} from "@ant-design/icons";
import { useLeadsController } from "../Controllers/LeadsController";
import { PendingLeadsContext } from "../Contexts/PendingLeads/provider";
import { OldLeadsContext } from "../Contexts/OldLeads/provider";
import styles from "./Layouts.module.css";

export const Layout = ({ children }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [location, setLocation] = useLocation();
  const { handleLogout } = useAuthController();
  const { user } = useContext(UserContext);
  const { Header, Sider, Content } = AntdLayout;
  const [loadingPendingLeads, setLoadingPendingLeads] = useState(true);
  const [loadingOldLeads, setLoadingOldLeads] = useState(true);
  const [leadsPendentes, setLeadsPendentes] = useState([]);
  const [oldLeads, setOldLeads] = useState([]);

  const { pendingLeadAccepted, setPendingLeadAccepted } = useContext(
    PendingLeadsContext
  );

  const {
    modifiedStatusOrAddedObservationOfOldLead,
    setModifiedStatusOrAddedObservationOfOldLead,
  } = useContext(OldLeadsContext);

  const { getAvailableLeads, getOldLeads } = useLeadsController();

  const fetchLeadsPendentes = useCallback(async () => {
    setLoadingPendingLeads(true);
    try {
      const { data } = await getAvailableLeads();

      if (data) {
        setLeadsPendentes(data);
      } else {
        setLeadsPendentes([]);
      }
    } catch (err) {
      setLeadsPendentes([]);
    }
    setLoadingPendingLeads(false);
  }, [getAvailableLeads]);

  const fetchOldLeads = useCallback(async () => {
    try {
      const { data } = await getOldLeads(user.id);

      if (data) {
        setOldLeads(data);
      } else {
        setOldLeads([]);
      }
    } catch (err) {
      setOldLeads([]);
    }

    setLoadingOldLeads(false);
  }, [getOldLeads, user]);

  useEffect(() => {
    if (location && user && user.id) {
      fetchLeadsPendentes();
      fetchOldLeads();
    }
  }, [fetchLeadsPendentes, fetchOldLeads, location, user]);

  useEffect(() => {
    if (pendingLeadAccepted) {
      fetchLeadsPendentes();
      setPendingLeadAccepted(0);
    }
  }, [pendingLeadAccepted, fetchLeadsPendentes, setPendingLeadAccepted]);

  useEffect(() => {
    if (modifiedStatusOrAddedObservationOfOldLead) {
      fetchOldLeads();
      setModifiedStatusOrAddedObservationOfOldLead(0);
    }
  }, [
    modifiedStatusOrAddedObservationOfOldLead,
    fetchOldLeads,
    setModifiedStatusOrAddedObservationOfOldLead,
  ]);

  const links = {
    notifications: "1",
    tasks: "2",
    leads: "3",
    salesFunnel: "4",
  };

  const [selectedKeys, setSelectedKeys] = useState();

  useEffect(() => {
    if (links[location.split("/")[1]]) {
      setSelectedKeys([links[location.split("/")[1]].toString()]);
    }

    // via https://www.pullrequest.com/blog/adding-segment-for-react/
    window.analytics.page();
  }, [location]);

  const openHelp = () => {
    const url = `https://api.whatsapp.com/send?phone=5511933970337&text=Quero%20entrar%20em%20contato%20com%20a%20equipe%20de%20suporte%20iBroker`;
    window.open(url);
  };

  const openHandbook = () => {
    const url = '/politicas.pdf';
    window.open(url);
  }

  const LeftMenu = () => {
    return (
      <Menu selectedKeys={selectedKeys} mode="inline">
        <Menu.Item key="1" icon={<AlertOutlined />}>
          <Link href="/notifications">
            <a href>Notificações</a>

            <Badge
              count={leadsPendentes.length + oldLeads.length}
              style={{ marginLeft: "32px" }}
            />
          </Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<CopyOutlined />}>
          <Link href="/tasks">
            <a href>Tarefas</a>
          </Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<ContactsOutlined />}>
          <Link href="/leads">
            <a href>Leads</a>
          </Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<FunnelPlotOutlined />}>
          <Link href="/salesFunnel">
            <a href>Funil de vendas</a>
          </Link>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <div className={styles.mobileAlert}>
        <div className={styles.mobileContainer}>
          <img
            style={{ paddingTop: "2em" }}
            src="/ibroker_logo.png"
            alt="iBroker logo"
          />
          <br />
          <img
            style={{ padding: "2em" }}
            src="/ilustracion_mobile.png"
            alt="iBroker ilustracion"
            width="250px"
          />
          {/* <a href="#" title="Abra no aplicativo AoCubo" id="mobile-redirect-button">
            <button class="generic-input-confirm-button">
              Abra no aplicativo AoCubo
            </button>
          </a> */}
        </div>
        <div className={styles.mobileBottomContainer}>
          <div className={styles.redirectText}>Baixe o aplicativo</div>
          <div className={styles.redirectSubtext}>
            Fique por dentro do seu trabalho, <br />
            de qualquer lugar
          </div>
          <a
            class="redirect-iOS-element"
            target="_blank"
            href="https://apps.apple.com/br/app/ibroker-by-ao-cubo/id1506611202"
          >
            <img
              className={styles.redirectBadgeIos}
              alt="Apple App Store"
              src="/appstore.png"
            />
          </a>
          <a
            class="redirect-Android-element"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.ibrokerbyaocuboapp.br"
          >
            <img
              className={styles.redirectBadgeAndroid}
              alt="Androd Play Store"
              src="/playstore.png"
            />
          </a>
        </div>
      </div>
      <AntdLayout
        className={styles.globalLayout}
        style={{ minHeight: "100vh" }}
      >
        <Sider
          theme="light"
          style={{ boxShadow: "2px 0px 10px rgba(0,0,0,0.1)" }}
        >
          <img
            style={{ padding: "2em" }}
            src="/ibroker_logo.png"
            alt="iBroker logo"
          />
          <LeftMenu />
        </Sider>
        <AntdLayout>
          <Header
            style={{
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
            }}
          >
            {/* <Button
              icon={<MenuOutlined />}
              className="menu"
              onClick={()=>{setShowDrawer(true)}}
            /> */}
            <Drawer
              // title="Topics"
              placement="left"
              onClick={() => setShowDrawer(false)}
              onClose={() => setShowDrawer(false)}
              visible={showDrawer}
            >
              <LeftMenu />
            </Drawer>
            <p style={{ opacity: 0.5 }}>Olá, {user.name}!</p>
            <Menu
              selectable={false}
              style={{ border: "none", marginTop: "-2px" }}
              mode="horizontal"
            >
              <Menu.Item key="1">
                <Tooltip title="Leia a política de negócios e manual de utilização do iBroker Web">
                  <Button onClick={openHandbook}>Manual</Button>
                </Tooltip>
              </Menu.Item>
              <Menu.Item key="2">
                <Tooltip title="Entre em contato com a equipe de suporte iBroker">
                  <Button onClick={openHelp}>Ajuda</Button>
                </Tooltip>
              </Menu.Item>
              <Menu.Item key="4">
                <Button type="link" onClick={handleLogout}>
                  Logout
                </Button>
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ flex: "1 1 auto" }}>
            <div
              style={{
                padding: 24,
                minHeight: 360,
                width: "850px",
                margin: "0 auto",
                marginTop: "32px",
              }}
            >
              {children}
            </div>
          </Content>
        </AntdLayout>
      </AntdLayout>
    </>
  );
};
