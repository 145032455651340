import { createContext, useState } from "react";

export const PendingLeadsContext = createContext(null);

export const PendingLeadsProvider = ({ children }) => {
  const [pendingLeadAccepted, setPendingLeadAccepted] = useState(0);

  return (
    <PendingLeadsContext.Provider
      value={{ pendingLeadAccepted, setPendingLeadAccepted }}
    >
      {children}
    </PendingLeadsContext.Provider>
  );
};
