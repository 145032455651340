import { useTaskController } from "../../Controllers/TaskController";
import { TaskTable } from "../../Components/TaskTable";
import { useEffect, useState } from "react";
import { Link } from "wouter";
import { Button, Table, Tooltip, Tag } from "antd";

export const TestTaskList = () => {
  const {
    getTaskList,
    loadingTaskList: loading,
    taskList,
    completeTask,
    loadingCompleteTask,
  } = useTaskController();

  const onCompleTask = async (id) => {
    await completeTask(id);
    getTaskList();
    window.analytics.track("Tarefa completa");
  };

  useEffect(() => {
    getTaskList();
  }, []);

  return (
    <TaskTable 
      onCompleTask={onCompleTask} 
      taskList={taskList} 
      loading={loading} 
      loadingCompleteTask={loadingCompleteTask}
    />
  );
};

export const TestTaskView = ({ taskId }) => {
  const {
    loadingTask: loading,
    task,
    getTask,
    errorViewTask,
  } = useTaskController();

  useEffect(() => {
    getTask(taskId);
  }, [taskId]);

  if (errorViewTask) return <div>Error.</div>;

  return (
    <div>
      {loading || task == null ? (
        <p>Loading...</p>
      ) : (
        <div>
          {JSON.stringify(task)}
          <Link href={"/tasks/lead/" + task.leadId}>
            Ver tasks de este lead
          </Link>
        </div>
      )}
    </div>
  );
};

export const TestCreateTask = () => {
  const {
    loadingCreateTask: loading,
    taskCreated,
    createTask,
  } = useTaskController();

  if (loading) return <div>Loading</div>;

  const taskData = {
    date:
      "2021/0" +
      Math.round(Math.random() * (9 - 1) + 1) +
      "/" +
      Math.round(Math.random() * (31 - 10) + 10),
    time:
      Math.round(Math.random() * (23 - 12) + 12) +
      ":" +
      Math.round(Math.random() * (59 - 10) + 10),
    leadContact: "Juan",
    leadId: 75458,
    type: "Reunião",
  };

  return (
    <div>
      <p>Crear task con estos datos: {JSON.stringify(taskData)}</p>

      <Button
        onClick={() =>
          createTask(
            taskData.date,
            taskData.time,
            taskData.leadId,
            taskData.type
          )
        }
      >
        {" "}
        Crear
      </Button>

      {taskCreated ? <p>Tarea cread con exito: {taskCreated.id}</p> : null}
    </div>
  );
};

export const TestDeleteTask = ({ taskId }) => {
  const [taskDeleted, setTaskDeleted] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(false);

  const {
    loadingDeleteTask,
    deleteTask,
    completeTask,
    loadingCompleteTask,
  } = useTaskController();

  if (loadingDeleteTask || loadingCompleteTask) return <div>Loading</div>;

  if (taskDeleted) return <div>Task eliminada</div>;
  if (taskCompleted) return <div>Task finalizada</div>;

  return (
    <div>
      <Button
        onClick={() => deleteTask(taskId).then(() => setTaskDeleted(true))}
      >
        Eliminar
      </Button>
      <Button
        onClick={() => completeTask(taskId).then(() => setTaskCompleted(true))}
      >
        Finalizar
      </Button>
    </div>
  );
};

export const TestTasksLead = ({ leadId }) => {
  const {
    loadingFindTasks: loading,
    taskList,
    findTasks,
  } = useTaskController();

  useEffect(() => {
    findTasks(leadId);
  }, [leadId]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        taskList.map((task) => (
          <p key={task.id}>
            <Link href={"/task/" + task.id}>{task.id}</Link>
          </p>
        ))
      )}
    </div>
  );
};
