import api from "../../service/api";
import { useCallback, useContext } from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { UserContext } from "../../Contexts/User/provider";

dayjs.extend(isBetween);

export const STATUS_OPTIONS = {
  1: "Recebido",
  8: "Aguardando Retorno",
  2: "Escolhendo",
  3: "Visita Agendada",
  4: "Em proposta",
  7: "Contato futuro",
  5: "Finalizado",
  6: "Perdido"
};

export const STATUS_OPTIONS_PLURAL = {
  1: {
    name: "Leads Recebidos",
    order: 1,
    selectValue: 1,
  },
  8: {
    name: "Aguardando Retorno",
    order: 2,
    selectValue: 8
  },
  2: {
    name: "Escolhendo Imovéis",
    order: 3,
    selectValue: 2,
  },
  3: {
    name: "Visitas Agendadas",
    order: 4,
    selectValue: 3,
  },
  4: {
    name: "Em Propostas",
    order: 5,
    selectValue: 4,
  },
  7: {
    name: "Contato futuro",
    order: 6,
    selectValue: 7,
  },
  6: {
    name: "Perdidos",
    order: 7,
    selectValue: 6,
  },
  5: {
    name: "Negócio Fechado",
    order: 8,
    selectValue: 5,
  }
};

export const NEGOTIATION_OPTIONS = {
  1: "Frio",
  2: "Morno",
  3: "Quente",
};

export const TYPE_LEAD = {
  novoLead: "Novo Lead",
  ofertaAtiva: "Oferta Ativa",
};

export const useLeadsController = () => {
  const { user } = useContext(UserContext);

  const getAllLeads = useCallback(async () => {
    const response = await api.post("leads/search", {
      status: 0,
      statusNegotiation: 0,
    });

    return response;
  }, []);

  const getAvailableLeads = useCallback(async () => {
    const response = await api.post("leads/v2/findLeadsAvailable");

    return response;
  }, []);

  const getOldLeads = useCallback(async (userId) => {
    const id = userId || user.id;
    const response = await api.get(`/brokerStats/${id}`);

    return response;
  }, []);

  const acceptLead = async (id) => {
    const response = await api.put(`leads/accept/${id}`);

    return response;
  };

  const editStatus = async (id, status) => {
    const response = await api.put(`leads/edit/${id}`, {
      status,
    });

    return response;
  };

  const filter = useCallback((leads, filters) => {
    // console.log("leads",leads);

    if (Number(filters.status)) {
      leads = leads.filter((lead) => lead.status === Number(filters.status));
    }

    if (Number(filters.statusNegotiation)) {
      leads = leads.filter(
        (lead) => lead.statusNegotiation === Number(filters.statusNegotiation)
      );
    }

    if (filters.contact) {
      leads = leads.filter(
        (lead) =>
          lead.cellphone.includes(filters.contact) +
          lead.contact.toLowerCase().includes(filters.contact.toLowerCase()) +
          lead.email.toLowerCase().includes(filters.contact.toLowerCase())
      );
    }

    if (filters.typeLead) {
      leads = leads.filter((lead) => {
        if (filters.typeLead === TYPE_LEAD.novoLead) {
          return !lead.typeLead || lead.typeLead === TYPE_LEAD.novoLead;
        }
        if (filters.typeLead === TYPE_LEAD.ofertaAtiva) {
          return lead.typeLead && lead.typeLead !== TYPE_LEAD.novoLead;
        }
        return lead;
      });
    }

    // console.log(filters);
    if (filters.dateRange && filters.dateRange[0] && filters.dateRange[1]) {
      leads = leads.filter((lead) =>
        dayjs(lead.createdAt).isBetween(
          filters.dateRange[0],
          filters.dateRange[1],
          "day",
          "[]"
        )
      );
    }

    return leads;
  }, []);

  const typeLeadOptions = [
    {
      value: 0,
      text: "Todos",
    },
    {
      value: TYPE_LEAD.novoLead,
      text: TYPE_LEAD.novoLead,
    },
    {
      value: TYPE_LEAD.ofertaAtiva,
      text: TYPE_LEAD.ofertaAtiva,
    },
  ];

  const statusOptions = [
    {
      value: 0,
      text: "Todos",
    },
    {
      value: 1,
      text: "Recebido",
    },
    {
      value: 8,
      text: "Aguardando Retorno"
    },
    {
      value: 2,
      text: "Escolhendo",
    },
    {
      value: 3,
      text: "Visita Agendada",
    },
    {
      value: 4,
      text: "Em proposta",
    },
    {
      value: 7,
      text: "Contato futuro",
    },
    {
      value: 5,
      text: "Negócio Fechado",
    },
    {
      value: 6,
      text: "Perdido",
    }
  ];

  const statusNegotiationOptions = [
    {
      value: 0,
      text: "Todos",
    },
    {
      value: 1,
      text: "Frio",
    },
    {
      value: 2,
      text: "Morno",
    },
    {
      value: 3,
      text: "Quente",
    },
  ];

  const getLead = useCallback(async (id) => {
    const response = await api.get(`leads/find/${id}`);

    return response;
  }, []);

  const getChat = useCallback(async (id) => {
    const response = await api.get(`conversations/find/${id}`);

    return response;
  }, []);

  const getComments = useCallback(async (id) => {
    const response = await api.get(`comments/find/${id}`);

    return response;
  }, []);

  const addComment = async (comment, idLead) => {
    const response = await api.post(`comments`, {
      body: comment,
      idLead,
    });

    return response;
  };

  const getFunnil = useCallback(async () => {
    const { data } = await getAllLeads();

    let totalLeads = data.length;

    if (totalLeads > 0) {
      console.log(STATUS_OPTIONS_PLURAL)

      const counts = data.reduce(
        (acc, current) => {
          if (isNaN(acc[current.status])) {
            acc[current.status] = 0;
          }
          acc[current.status]++;

          return acc;
        },
        { ...STATUS_OPTIONS_PLURAL }
      );

      console.log("counts", counts)

      const funnil = Object.keys(counts).map((k) => {
        let count = isNaN(counts[k]) ? 0 : counts[k];
        return {
          name: STATUS_OPTIONS_PLURAL[k].name,
          order: STATUS_OPTIONS_PLURAL[k].order,
          selectValue: STATUS_OPTIONS_PLURAL[k].selectValue,
          count,
          percentage: Math.round(((count * 100) / totalLeads) * 100) / 100,
        };
      });

      return [...funnil].sort((a, b) => a.order - b.order);
    }
  }, []);

  return {
    getAllLeads,
    getAvailableLeads,
    getOldLeads,
    acceptLead,
    editStatus,
    filter,
    typeLeadOptions,
    statusOptions,
    statusNegotiationOptions,
    getLead,
    getChat,
    getComments,
    addComment,
    getFunnil,
  };
};
