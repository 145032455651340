import {usePropertyController} from "../../Controllers/PropertyController";
import {useEffect} from "react";

export const TestPropertyView = ({propertyId}) => {


    const {loadingProperty: loading, getProperty, property, similar, getSimilar} = usePropertyController();

    useEffect( () => {
        getProperty(propertyId);
    }, [propertyId])

    useEffect(() => {
        if(property) getSimilar(property.id)
    }, [property]);

    return (
        <div>
            {loading || property == null ? <p>Loading...</p> :
                <pre>
                    {JSON.stringify(property).replace(/,/g, "\n")}
                    <br />
                    SIMILAR:
                    {JSON.stringify(similar)}
                </pre>
            }
        </div>
    )
}

