import { Avatar, List, Comment, Form, Input, Button, Empty } from "antd";
import React, { useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";

export const Observations = ({ observations, loading, newObservation }) => {
  const [text, setText] = useState("");

  const handleNewObservation = async () => {
    await newObservation(text);
    setText("");
  };

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={observations}
        locale={{ emptyText: <Empty description="Sem dados" /> }}
        loading={loading}
        renderItem={(item) => (
          <li>
            <Comment
              author={<>{moment(item.createdAt).format("L HH:mm")}</>}
              avatar={<Avatar src="/logo_aocubo.png" />}
              content={item.body}
              actions={[]}
            />
          </li>
        )}
      />
      <Comment
        avatar={<Avatar src="/logo_aocubo.png" />}
        content={
          <>
            <Form.Item>
              <Input.TextArea
                disabled={loading}
                rows={4}
                onChange={(e) => setText(e.target.value)}
                value={text}
              />
            </Form.Item>
            <Form.Item>
              <Button
                disabled={loading}
                htmlType="submit"
                loading={loading}
                onClick={handleNewObservation}
                type="primary"
              >
                Salvar
              </Button>
            </Form.Item>
          </>
        }
      />
    </div>
  );
};
