import { useEffect, useState, useCallback, useContext } from "react";
import { Select } from "../../Components/Select";
import { useLeadsController } from "../../Controllers/LeadsController";
import { LeadCard } from "../../Components/LeadCard";
import {
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Empty,
  Table,
  Tag,
  Tooltip,
  Descriptions,
  Card,
} from "antd";
import { Link, useRoute, useLocation } from "wouter";
import { Conversation } from "../../Components/Conversation";
import moment from "moment";
import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";

import { ContactsOutlined, MailOutlined } from "@ant-design/icons";

// import styles from "./Leads.module.css";
import { PendingLeadsContext } from "../../Contexts/PendingLeads/provider";

import {
  STATUS_OPTIONS,
  NEGOTIATION_OPTIONS,
  TYPE_LEAD,
} from "../../Controllers/LeadsController";

const { RangePicker } = DatePicker;
moment.locale("pt-br");

const extractFiltersFromUrl = (url) => {
  let params = url.split("&");
  let object = params.reduce((acc, filter) => {
    let [key, value] = filter.split("=");

    if(key === "status" || key === "statusNegotiation"){
      value = parseInt(value)
    } else if(key === "typeLead"){
      if(value === "1"){
        value = "Novo Lead";
      } else if (value === "2"){
        value = "Oferta Ativa";
      }
    } else if(key === "initDate" || key === "endDate"){
      value = moment(value);
    }

    acc[key] = value;

    return acc;
  }, {});

  return object
}

export const Leads = () => {
  const [match, params] = useRoute("/leads/filters/:filters");
  const initialFilters = params?.filters ? extractFiltersFromUrl(params.filters) : {};
  const [leads, setLeads] = useState([]);
  const [filterLeads, setFilterLeads] = useState([]);
  const [typeLead, setTypeLead] = useState(initialFilters.typeLead || 0);
  const [status, setStatus] = useState(initialFilters.status || 0);
  const [statusNegotiation, setStatusNegotiation] = useState(initialFilters.statusNegotiation || 0);
  const [dateRange, setDateRange] = useState([initialFilters.initDate || null, initialFilters.endDate || null]);
  const [contact, setContact] = useState("");
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(true);
  const { pendingLeadAccepted, setPendingLeadAccepted } = useContext(
    PendingLeadsContext
  );
  const [location, setLocation] = useLocation();

  const {
    getAllLeads,
    filter,
    typeLeadOptions,
    statusOptions,
    statusNegotiationOptions,
  } = useLeadsController();

  const fetchLeads = useCallback(async () => {
    setLoading(true);
    const { data } = await getAllLeads();
    setLoading(false);
    if (data) {
      setLeads(data);
    } else {
      setLeads([]);
    }
  }, [getAllLeads]);

  useEffect(() => {
    fetchLeads();
  }, [fetchLeads]);

  useEffect(() => {
    if (pendingLeadAccepted) {
      fetchLeads();
      setPendingLeadAccepted(0);
    }
  }, [pendingLeadAccepted, fetchLeads, setPendingLeadAccepted]);

  useEffect(() => {
    if (leads) {
      const filteredLeads = filter(leads, {
        typeLead,
        status,
        statusNegotiation,
        contact,
        dateRange,
      });
      setFilterLeads(filteredLeads);
    }
  }, [typeLead, status, statusNegotiation, contact, leads, dateRange, filter]);

  useEffect(() => {
    let filters = []
    if(typeLead != 0){
      let typeLeadoption = "";
      if(typeLead === "Novo Lead"){
        typeLeadoption = 1;
      } else if (typeLead === "Oferta Ativa"){
        typeLeadoption = 2;
      }
      filters.push(`typeLead=${typeLeadoption}`);
    }
    if(status != 0){
      filters.push(`status=${status}`);
    }
    if(statusNegotiation != 0){
      filters.push(`statusNegotiation=${statusNegotiation}`);
    }
    if(dateRange[0] != null && dateRange[1] != null){
      filters.push(`initDate=${dateRange[0].format("YYYY-MM-DD")}&endDate=${dateRange[1].format("YYYY-MM-DD")}`);
    }

    if(filters.length > 0){
      let urlString = `/leads/filters/${filters.join("&")}`;
  
      if( location != urlString){
        setLocation(urlString);
      }
    }
  }, [typeLead, status, statusNegotiation, dateRange])

  const columnas = [
    {
      title: "Nome",
      dataIndex: "contact",
      key: "nombre",
      render: (text, lead) => (
        <Link href={`/leads/${lead.id}`}>
          <Tooltip title="Ver Contato">
            <a>
              <Tag icon={<ContactsOutlined />} color={"geekblue"} key={text}>
                {text}
              </Tag>
            </a>
          </Tooltip>
        </Link>
      ),
    },
    {
      title: "Tipo de lead",
      dataIndex: "typeLead",
      key: "typeLead",
      align: "right",
      render: (typeLead, lead) => (
        <>
          {/* {typeLead} */}

          {typeLead && typeLead === TYPE_LEAD.ofertaAtiva
            ? TYPE_LEAD.ofertaAtiva
            : TYPE_LEAD.novoLead}
        </>
      ),
    },
    {
      title: "Status lead",
      dataIndex: "status",
      key: "status",
      align: "right",
      render: (status, lead) => <>{STATUS_OPTIONS[status]}</>,
    },
    {
      title: "Status de negociação",
      dataIndex: "statusNegotiation",
      key: "statusNegotiation",
      align: "right",
      render: (status, lead) => <>{NEGOTIATION_OPTIONS[status]}</>,
    },
    {
      title: "Atualizada",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "right",
      render: (updatedAt, lead) => (
        <div>
          {/* {updatedAt} */}
          {moment(updatedAt).format("L").toString()}
        </div>
      ),
    },
  ];

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
    .filterContainer .ant-select, .filterContainer .ant-picker { width: 100%; }
`,
        }}
      />
      <Row>
        <Col span={24}>
          <Form layout="vertical" className="filterContainer">
            <Row justify="space-between">
              <Descriptions
                bordered
                size="small"
                layout="vertical"
                style={{
                  backgroundColor: "#ffffff",
                  marginBottom: "24px",
                  width: "100%",
                }}
              >
                <Descriptions.Item label="Tipo de lead">
                  <Select
                    // className={styles.filterTypeLead}
                    onChange={setTypeLead}
                    defaultValue={initialFilters.typeLead || 0}
                    options={typeLeadOptions}
                    // width={"100%"}
                    // style={{ width: 400 }}
                    // style={{ width: 300 }}
                    dropdownMatchSelectWidth={false}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Status lead">
                  <Select
                    onChange={setStatus}
                    defaultValue={initialFilters.status || 0}
                    options={statusOptions}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Status negociação">
                  <Select
                    onChange={setStatusNegotiation}
                    defaultValue={initialFilters.statusNegotiation || 0}
                    options={statusNegotiationOptions}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Data do lead">
                  <RangePicker
                    value={dateRange}
                    onChange={setDateRange}
                    format={"DD/MM/YYYY"}
                    locale={locale}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Filtro">
                  <Input.Search
                    // className={styles.filter}
                    enterButton={false}
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    allowClear
                  />
                </Descriptions.Item>
              </Descriptions>
            </Row>

            <Table
              bordered
              loading={loading}
              columns={columnas}
              locale={{ emptyText: <Empty description="Sem dados" /> }}
              dataSource={filterLeads}
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};
