import { Button, Input, Form } from "antd";
import { Link } from "wouter";
import { useAuthController } from "../../Controllers/AuthController";

export const Register = () => {
  const { handleRegister, registered } = useAuthController();

  if (registered) {
    return (
      <>
        <div>registrado!!</div>
        <Link to="/login">
          <Button>Volver</Button>
        </Link>
      </>
    );
  }

  return (
    <div>
      <Link to="/login">
        <Button>Back</Button>
      </Link>
      <Form name="register" onFinish={handleRegister}>
        <Form.Item label="Nome Completo" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="E-mail" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Celular" name="cellphone">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
