import { Spin, Radio, Tooltip } from "antd";
import { STATUS_OPTIONS } from "../../Controllers/LeadsController";

export const LeadStatus = ({ status, onChange, loading }) => {
  const handleOnChange = (e) => {
    console.log("cambiando");
  };

  return (
    <>
      <Spin spinning={loading}>
        <Radio.Group value={status} onChange={(e) => onChange(e.target.value)}>
          {Object.keys(STATUS_OPTIONS).map((key) => (
            <Tooltip
              key={key}
              title={
                status === parseInt(key)
                  ? "Status atual do lead"
                  : `Alterar do status "${STATUS_OPTIONS[status]}" para o status "${STATUS_OPTIONS[key]}"`
              }
            >
              <Radio.Button key={key} value={parseInt(key)}>
                {STATUS_OPTIONS[key]}
              </Radio.Button>
            </Tooltip>
          ))}
        </Radio.Group>
      </Spin>
    </>
  );
};
