import { createContext, useState } from "react";

export const OldLeadsContext = createContext(null);

export const OldLeadsProvider = ({ children }) => {
  const [
    modifiedStatusOrAddedObservationOfOldLead,
    setModifiedStatusOrAddedObservationOfOldLead,
  ] = useState(0);

  return (
    <OldLeadsContext.Provider
      value={{
        modifiedStatusOrAddedObservationOfOldLead,
        setModifiedStatusOrAddedObservationOfOldLead,
      }}
    >
      {children}
    </OldLeadsContext.Provider>
  );
};
