import {Avatar, List, Comment, Empty} from "antd";
import React from "react";
import DayJS from "react-dayjs";

export const Conversation = ({conversation, loading}) => {
    return(
        <List
            itemLayout="horizontal"
            dataSource={conversation}
            loading={loading}
            locale={{emptyText: <Empty description="Sem dados" />}}
            renderItem={item => (
                <li>
                    <Comment
                        author={item.name}
                        avatar={<Avatar src={item.name === 'AoCubo' ? "/logo_aocubo.png": "/logo_user.png"} />}
                        content={item.message}
                        actions={[]}
                        datetime={item.date + " " + item.hour}
                    />
                </li>
            )}
        />
    )
}
