import { useAuthController } from "../../Controllers/AuthController";
import { Button, Form, Input, Card, Row, Col, Image, Typography } from "antd";
import styles from "./Login.module.css";

const { Paragraph, Text } = Typography;

export const Login = () => {
  const {
    handleLogin,
    loginRules,
    loadingLogin,
    forgotPasswordRules,
    handleForgotPassword,
    displayLoginForm,
    onForgotPasswordClick,
    onLoginClick,
    loadingForgotPassword,
  } = useAuthController();

  return (
    <div>
      <Row justify="center" align="middle" className={styles.loginLayout}>
        <Col xs={20} sm={14} md={12} lg={10} xl={6} xxl={4}>
          <Card>
            <Row justify="center">
              <Image
                className={styles.logoImage}
                src="/ibroker_logo.png"
                alt="iBroker logo"
                preview={false}
              />
            </Row>

            {displayLoginForm ? (
              <LoginForm
                handleLogin={handleLogin}
                loginRules={loginRules}
                loadingLogin={loadingLogin}
                onForgotPasswordClick={onForgotPasswordClick}
              />
            ) : (
              <ForgotPasswordForm
                handleForgotPassword={handleForgotPassword}
                forgotPasswordRules={forgotPasswordRules}
                onLoginClick={onLoginClick}
                loadingForgotPassword={loadingForgotPassword}
              />
            )}
          </Card>

          <Col span={24} className={styles.registerText}>
            <Text>
              Não tem uma conta?{" "}
              <a
                className={styles.registerLink}
                href={`https://api.whatsapp.com/send?phone=5511933970337&text=Quero%20descobrir%20como%20ser%20um%20corretor%20iBroker`}
                target="_blank"
                rel="noreferrer"
              >
                Cadastre-se
              </a>
            </Text>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export const LoginForm = ({
  handleLogin,
  loginRules,
  loadingLogin,
  onForgotPasswordClick,
}) => {
  return (
    <Form name="login" onFinish={handleLogin} layout="vertical">
      <Form.Item label="E-mail" name="email" rules={loginRules.email}>
        <Input />
      </Form.Item>

      <Form.Item label="Senha" name="password" rules={loginRules.password}>
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Col span={24}>
          <Button type="primary" htmlType="submit" loading={loadingLogin} block>
            Fazer login
          </Button>
        </Col>
        <Col span={24} className={styles.forgotPassword}>
          <Button type="link" onClick={onForgotPasswordClick}>
            Esqueci minha senha
          </Button>
        </Col>
      </Form.Item>
    </Form>
  );
};

export const ForgotPasswordForm = ({
  handleForgotPassword,
  forgotPasswordRules,
  onLoginClick,
  loadingForgotPassword,
}) => {
  return (
    <Form
      name="forgotPassword"
      onFinish={handleForgotPassword}
      layout="vertical"
      className={styles.forgotPasswordForm}
    >
      <Paragraph className={styles.forgotPasswordParagraph}>
        Digite o seu endereço de e-mail.
      </Paragraph>
      <Paragraph>Você receberá uma nova senha por e-mail.</Paragraph>

      <Form.Item
        label="E-mail"
        name="email"
        rules={forgotPasswordRules.email}
        className={styles.forgotPasswordEmail}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Col span={24}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingForgotPassword}
            block
          >
            Recuperar senha
          </Button>
        </Col>
        <Col span={24} className={styles.forgotPassword}>
          <Button type="link" onClick={onLoginClick}>
            Fazer login
          </Button>
        </Col>
      </Form.Item>
    </Form>
  );
};
