import { useContext, useState } from "react";
import { useLocation } from "wouter";
import api from "../../service/api";
import { UserContext } from "../../Contexts/User/provider";
import { message } from "antd";

export const useAuthController = () => {
  const [, setLocation] = useLocation();
  const [loadingLogin, setLoadingLogin] = useState(false);
  const { setUser, setLoggedIn } = useContext(UserContext);
  const [registered, setRegistered] = useState(false);
  const [displayLoginForm, setDisplayLoginForm] = useState(true);
  const [loadingForgotPassword, setLoadingForgotPassword] = useState(false);

  const loginRules = {
    email: [
      {
        required: true,
        message: "Por favor insira seu e-mail!",
      },
      {
        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: "Por favor insira um e-mail válido!",
      },
    ],
    password: [
      {
        required: true,
        message: "Por favor insira sua senha!",
      },
    ],
  };

  const forgotPasswordRules = {
    email: [
      {
        required: true,
        message: "Por favor insira seu e-mail!",
      },
      {
        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: "Por favor insira um e-mail válido!",
      },
    ],
  };

  const handleLogin = async (values) => {
    setLoadingLogin(true);
    try {
      const { data } = await api.post("accounts/login", values);
      if (data) {
        window.analytics.identify(data.user.id, {
          email: data.user.email,
        });
        localStorage.setItem("broker_token", data.token);
        setLoggedIn(true);
        setUser(data.user);
        setLocation("/notifications");
      }
    } catch (e) {
      setLoadingLogin(false);
      message.error("Usuário e senha não correspondem");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("broker_token");
    setLoggedIn(false);
    setUser({});
    setLocation("/login");
  };

  const handleRegister = async (values) => {
    setLoadingLogin(true);
    //const { data } = await api.post("landingpage", values);
    setRegistered(true);
  };

  const handleForgotPassword = async (values) => {
    setLoadingForgotPassword(true);
    try {
      const result = await api.post("/accounts/forgot-password", values);
      setLoadingForgotPassword(false);
      message.success("A senha foi enviada com sucesso para o seu e-mail");
    } catch (e) {
      setLoadingForgotPassword(false);
      message.error("Ocorreu um erro ao enviar a senha");
    }
  };

  const onForgotPasswordClick = () => {
    setDisplayLoginForm(false);
  };

  const onLoginClick = () => {
    setDisplayLoginForm(true);
  };

  return {
    handleLogin,
    handleLogout,
    loginRules,
    handleRegister,
    registered,
    loadingLogin,
    handleForgotPassword,
    forgotPasswordRules,
    displayLoginForm,
    onForgotPasswordClick,
    onLoginClick,
    loadingForgotPassword,
  };
};
