import { Link } from "wouter";
import { Button, Table, Tooltip, Tag, Typography, Empty } from "antd";
import { PropertyButton } from "../PropertyButton";
import {
  LinkOutlined,
  CheckOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "moment/locale/pt-br";

export const TaskTable = ({
  onCompleTask,
  taskList,
  loading,
  loadingCompleteTask,
}) => {
  const columnas = [
    {
      title: "",
      dataIndex: "id",
      key: "action",
      render: (taskId) => (
        <Tooltip title="Marque a tarefa como concluída">
          <Button
            shape="circle"
            size="small"
            onClick={() => onCompleTask(taskId)}
            icon={<CheckOutlined />}
          ></Button>
        </Tooltip>
      ),
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (text) => <Tooltip title="Tipo de tarefa">{text}</Tooltip>,
    },
    {
      title: "Contato",
      dataIndex: "leadContact",
      key: "leadContact",
      render: (text, task) => (
        <Link to={`/leads/${task.leadId}`}>
          <Tooltip title="Ver Contato">
            <a>
              <Tag icon={<ContactsOutlined />} color={"geekblue"} key={text}>
                {text}
              </Tag>
            </a>
          </Tooltip>
        </Link>
      ),
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (text) => {
        const dateToShow = moment(text, "DD/MM/YYYY")
          .format("DD/MM/YYYY")
          .toString();

        return moment().add(-1,'days').isAfter(moment(text, "DD/MM/YYYY")) ? (
          <Typography.Text type="danger">{dateToShow}</Typography.Text>
        ) : (
          <>{dateToShow}</>
        );
      },
    },
    {
      title: "Hora",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Propriedade",
      dataIndex: "id",
      key: "property",
      render: (leadId, task) => <PropertyButton task={task} />,
    },
  ];

  return (
    <Table
      columns={columnas}
      loading={loading || loadingCompleteTask}
      locale={{ emptyText: <Empty description="Sem dados" /> }}
      dataSource={taskList}
    />
  );
};
