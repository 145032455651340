import { useContext } from "react";
import ReactGA from "react-ga";
import { Switch, Route, Link, Redirect } from "wouter";
import { UserContext } from "./Contexts/User/provider";
import { Layout } from "./Layouts";
import { Leads } from "./Layouts/Leads";
import { Login } from "./Layouts/Login";
import {
  TestCreateTask,
  TestDeleteTask,
  TestTaskList,
  TestTasksLead,
  TestTaskView,
} from "./Layouts/Tasks";
import { Register } from "./Layouts/Register";
import { LeadPage } from "./Layouts/LeadPage";
import { TestPropertyView } from "./Layouts/Property";
import { SalesFunnel } from "./Layouts/SalesFunnel";
import { Button, Result } from "antd";
import { Notifications } from "./Layouts/Notifications";

const TRACKING_ID = "UA-150855337-1";
ReactGA.initialize(TRACKING_ID);

export const NotFoundPage = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Desculpe, a página que você visitou não existe."
      extra={
        <Link href="/tasks">
          <Button type="primary">Voltar</Button>
        </Link>
      }
    />
  );
};

export const App = () => {
  const { isLoggedIn, loading } = useContext(UserContext);

  if (loading) {
    return <div>loading...</div>;
  }

  if (!isLoggedIn) {
    return (
      <>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route>
            <Redirect to="/login" />
          </Route>
        </Switch>
      </>
    );
  }

  return (
    <>
      <Layout>
        <Switch>
          <Route path="/">
            <Redirect to="/notifications" />
          </Route>
          <Route path="/notifications">
            <Notifications />
          </Route>
          <Route path="/tasks">
            <TestTaskList />
          </Route>
          <Route path="/task/new">
            <TestCreateTask />
          </Route>
          <Route path="/task/:id">
            {(params) => <TestTaskView taskId={params.id} />}
          </Route>
          <Route path="/tasks/lead/:leadId">
            {(params) => <TestTasksLead leadId={params.leadId} />}
          </Route>
          <Route path="/task/delete/:id">
            {(params) => <TestDeleteTask taskId={params.id} />}
          </Route>

          <Route path="/property/:id">
            {(params) => <TestPropertyView propertyId={params.id} />}
          </Route>
          <Route path="/leads">
            <Leads />
          </Route>
          <Route path="/leads/filters/:filters">
            <Leads />
          </Route>
          <Route path="/leads/:id">
            <LeadPage />
          </Route>
          <Route path="/salesFunnel">
            <SalesFunnel />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </Layout>
    </>
  );
};
