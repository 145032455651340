import { Descriptions, Tooltip } from "antd";

export const InfoLead = ({ lead, leadLoading, property, loadingProperty }) => {
  const leadInfo = {
    ...(lead.contact !== "" && {
      Nome: lead.contact,
    }),
    ...(lead.cellphone !== "" && {
      Telefone: (
        <Tooltip title="Telefone">
          <a href={`tel:${lead.cellphone}`}>{lead.cellphone}</a>
        </Tooltip>
      ),
    }),
    ...(lead.whatsapp !== "" && {
      WhatsApp: (
        <Tooltip title="WhatsApp">
          <a
            href={`https://api.whatsapp.com/send?phone=${lead.whatsapp}`}
            target="_blank"
            rel="noreferrer"
          >
            {lead.whatsapp}
          </a>
        </Tooltip>
      ),
    }),
    ...(lead.email !== "" && {
      "E-mail": (
        <Tooltip title="E-mail">
          <a href={`mailto:${lead.email}`}>{lead.email}</a>
        </Tooltip>
      ),
    }),
    ...(lead.link !== "" && {
      Imovel: (
        <Tooltip title="Ver propriedade em AoCubo">
          <a href={lead.link} target="_blank" rel="noreferrer">
            {property && property.local ? property.local : "Veja em AoCubo"}
          </a>
        </Tooltip>
      ),
    }),
    ...(lead.zone !== "" && {
      Zona: <Tooltip title="Zona">{lead.zone}</Tooltip>,
    }),
  };
  return (
    <Descriptions
      style={{ marginTop: "24px" }}
      title={null}
      size={"small"}
      bordered
    >
      {Object.keys(leadInfo).map((item, i) => {
        return (
          <Descriptions.Item key={`description_${i}`} label={item}>
            {leadInfo[item]}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
};
