import { List, Skeleton, Button, Avatar, Typography, Tooltip } from "antd";
import { 
  ContactsOutlined, 
  PushpinOutlined,
} from "@ant-design/icons";
import { Link } from "wouter";
import styles from "./PendingAndOldLeadCard.module.css";
import moment from "moment";
import "moment/locale/pt-br";

export const PendingAndOldLeadCard = ({
  lead,
  loading,
  type = "pending",
  handleAceitar,
}) => {
  return (
    <List.Item
      className={styles.leadItem}
      actions={[
        !loading && (
          <div style={{textAlign: "left", fontSize: "12px"}}>
          <div>Desde {moment(lead.createdAt).format("DD/MM/YYYY")}</div>
        </div>
         
        ),
        !loading && type === "pending" ? (
          <Tooltip title="Aceitar lead pendente">
            <Button
              onClick={() => {
                handleAceitar(lead.id);
              }}
              type="default"
            >
              Aceitar
            </Button>
          </Tooltip>
        ) : (
          <Link href={`leads/${lead.id}`}>
            <Tooltip title="Ver lead antigo">
              <Button type="default">Visualizar</Button>
            </Tooltip>
          </Link>
        ),
      ]}
    >
      <Skeleton
        avatar
        title={false}
        className={styles.skeletonListItem}
        loading={loading}
        active
      >
        <List.Item.Meta
          avatar={
            <Avatar
              size={"default"}
              style={{ color: "#16BA80", margin: "8px", fontSize: "24px" }}
              src={<ContactsOutlined />}
              shape="square"
            />
          }
          title={<Tooltip title="Contato">{lead.contact}</Tooltip>}
          description={
            <Tooltip title="Zona">
            <Typography.Text code><PushpinOutlined/>&nbsp;{lead.zone}</Typography.Text>
          </Tooltip>
          }
        />
      </Skeleton>
    </List.Item>
  );
};
